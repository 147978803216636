import React, { FC, useContext } from 'react';
import { Button, Modal, Space, Table, Tag, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { IProduct } from '../../models/IProduct';
import './product-list.scss';
import { Context } from '../../index';

interface ProductListProps {
	products: IProduct[];
}

const ProductList: FC<ProductListProps> = ({ products = [] }) => {
	const { productStore } = useContext(Context);
	const history = useHistory();

	const columns = [
		{
			title: '',
			dataIndex: 'images',
			width: 100,
			render(images: Array<string>) {
				const [img = ''] = images;
				return (
					<img
						src={img}
						style={{ objectFit: 'contain', height: 80, width: 80, paddingTop: 20 }}
						alt='Изображение товара'
					/>
				);
			},
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			width: 500,
			render(text: String) {
				return <span className='table-text'>{text}</span>;
			},
		},
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
			width: 350,
		},
		{
			title: 'Категория / Тип',
			align: 'center' as 'center',
			width: 300,
			render(text: string, record: IProduct) {
				return (
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<Tag color='green'>{record.category.displayName}</Tag>
						<Tag color='blue' style={{ marginTop: '1rem' }}>
							{record?.type?.name}
						</Tag>
					</div>
				);
			},
		},
		{
			title: 'Действия',
			render: (text: any, record: IProduct) => (
				<Space size='middle'>
					<Tooltip title='Редактировать'>
						<Button
							onClick={() => history.push(`/product/${record._id}/edit`)}
							type='primary'
							shape='circle'
							icon={<EditOutlined />}
						/>
					</Tooltip>
					<Tooltip title='Удалить'>
						<Button
							onClick={() => confirmDelete(record)}
							type='primary'
							shape='circle'
							danger
							icon={<DeleteOutlined />}
						/>
					</Tooltip>
				</Space>
			),
		},
	];

	const deleteProduct = (productId: string) => {
		if (productId) {
			productStore.deleteProduct(productId);
		}
	};

	function confirmDelete(product: IProduct) {
		if (!product) return;
		Modal.confirm({
			title: 'Подвердить удаление',
			icon: <ExclamationCircleOutlined />,
			content: `Вы действительно хотите удалить «${product.name}» ?`,
			okText: 'Подтверждаю',
			cancelText: 'Отмена',
			onOk: () => deleteProduct(product._id),
		});
	}

	return (
		<div className='product'>
			<div className='product__action'>
				<Button onClick={() => history.push('/product/add')} size='small' type='primary' icon={<PlusOutlined />}>
					Добавить
				</Button>
			</div>
			<Table columns={columns} dataSource={products} rowKey='_id' />
		</div>
	);
};

export default observer(ProductList);
