import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const breadcrumbNameMap = {
	'/products': 'Товары',
	'/product': 'Товары',
	'/product/categories': 'Категории товаров',
	'/product/types': 'Типы товаров',
	'/product/import': 'Импорт товаров',
	'/product/add': 'Добавление товара',
	'/articles': 'Статьи',
	'/article': 'Статьи',
	'/article/add': 'Добавление статьи',
	'/users': 'Пользователи',
};

const Breadcrumbs = () => {
	const location = useLocation();
	const pathSnippets = location.pathname.split('/').filter(i => i);
	const extraBreadcrumbItems = pathSnippets.map((path, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
		if (breadcrumbNameMap[url]) {
			return (
				<Breadcrumb.Item key={url}>
					<Link to={url}>{breadcrumbNameMap[url]}</Link>
				</Breadcrumb.Item>
			);
		}
		return (
			<Breadcrumb.Item key={path}>
				<span>{path}</span>
			</Breadcrumb.Item>
		);
	});
	const breadcrumbItems = [
		<Breadcrumb.Item key='home'>
			<Link to='/'>Главная</Link>
		</Breadcrumb.Item>,
	].concat(extraBreadcrumbItems);
	return (
		<>
			{location.pathname !== '/' && <Breadcrumb style={{ margin: '16px 0' }}>{breadcrumbItems}</Breadcrumb>}
			{location.pathname === '/' && <div style={{ margin: '16px 0' }} />}
		</>
	);
};

export default Breadcrumbs;
