import React, { FC, useContext, useState } from 'react';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import MainLayout from '../../components/app/MainLayout';
import IPage from '../../models/IPage';
import { Context } from '../../index';
import { IProductCategoryDto } from '../../models/IProductCategory';

const ProductCategoryAdd: FC<IPage> = ({ name }) => {
	const { productStore } = useContext(Context);
	const history = useHistory();
	const [error, setError] = useState<string | null>(null);

	const onFinish = async (categoryData: IProductCategoryDto) => {
		const resp = await productStore.addProductCategory(categoryData);
		if (resp && resp.status === 200) {
			history.push('/product/categories');
		} else {
			setError(resp?.statusText || 'Ошибка запрсоа');
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};
	return (
		<MainLayout pageName={name}>
			{error && (
				<Alert
					message='Ошибка сервера'
					type='error'
					showIcon
					description={error}
					action={
						<Button size='small' danger onClick={() => history.push('/product/categories')}>
							Вернуться на страницу категорий
						</Button>
					}
				/>
			)}
			{!error && (
				<Form name='basic' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed}>
					<Row gutter={[40, 0]} justify='start'>
						<Col span={12}>
							<Form.Item
								label='Название'
								name='name'
								rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label='Альтернативное название' name='displayName'>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label='Описание' name='description'>
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item>
								<Button type='primary' htmlType='submit'>
									Добавить категорию
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			)}
		</MainLayout>
	);
};

export default ProductCategoryAdd;
