import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Alert, Button, Form, Input } from 'antd';
import { Context } from '../../index';

const LoginForm: FC = () => {
	const { userStore: store } = useContext(Context);

	const onFinish = async (values: any) => {
		await store.login(values.login, values.password);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const onFormChange = () => {
		store.setError('');
	};

	return (
		<>
			{store.error !== '' && (
				<div className='auth__alert'>
					<Alert message='Внимание' type='error' showIcon description={store.error} />
				</div>
			)}
			<Form
				name='basic'
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 18 }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				onChange={onFormChange}
			>
				<Form.Item
					label='Логин'
					name='login'
					rules={[
						{ required: true, message: 'Логин обязателен' },
						() => ({
							validator(_, value) {
								if (/^[a-zA-Z0-9]+$/gm.test(value)) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('Допускаются только латинские цифры и буквы без проблеьных симоволов'));
							},
						}),
					]}
				>
					<Input disabled={store.isLoading} />
				</Form.Item>

				<Form.Item label='Пароль' name='password' rules={[{ required: true, message: 'Пароль обязателен' }]}>
					<Input.Password disabled={store.isLoading} />
				</Form.Item>

				<div className='auth__action'>
					<Form.Item>
						<Button loading={store.isLoading} type='primary' htmlType='submit'>
							Войти в систему
						</Button>
					</Form.Item>
				</div>
			</Form>
		</>
	);
};

export default observer(LoginForm);
