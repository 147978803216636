import React, { FC, useContext, useEffect } from 'react';
import { Button, Card, Col, Modal, Row, Tooltip } from 'antd';
import { DeleteOutlined, EditFilled, ExclamationCircleOutlined, EyeFilled, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import 'moment/locale/ru';
import MainLayout from '../../components/app/MainLayout';
import IPage from '../../models/IPage';
import './article-list.scss';
import { Context } from '../../index';

moment.locale('ru');

const ArticleListPage: FC<IPage> = ({ name }) => {
	const history = useHistory();
	const { articleStore } = useContext(Context);

	useEffect(() => {
		articleStore.getArticles();
	}, []);

	function displayDate(createdAt: string) {
		return moment(createdAt).format('DD MMMM YYYY');
	}

	function openArticle(id: string, humanUrl: string) {
		if (id && humanUrl) {
			const link = `${process.env.REACT_APP_CLIENT_HOST}/articles/${id}/${humanUrl}`;
			window.open(link, '_blank');
		}
	}

	function confirmDelete(id: string, title: string) {
		if (!id && !title) return;
		Modal.confirm({
			title: 'Подвердить удаление',
			icon: <ExclamationCircleOutlined />,
			content: `Вы действительно хотите удалить «${title}» ?`,
			okText: 'Подтверждаю',
			cancelText: 'Отмена',
			onOk: () => deleteArticle(id),
		});
	}

	const deleteArticle = async (articleId: string) => {
		if (articleId) {
			await articleStore.deleteArticle(articleId);
		}
	};

	return (
		<MainLayout pageName={name}>
			<div className='articles'>
				<div className='articles__action'>
					<Button onClick={() => history.push('/article/add')} size='small' type='primary' icon={<PlusOutlined />}>
						Добавить
					</Button>
				</div>
				<div className='articles__list'>
					<Row gutter={[20, 20]}>
						{articleStore.articles.map(article => (
							<Col key={article._id} span={24}>
								<Card
									title={article.title}
									extra={<span>{displayDate(article.createdAt)}</span>}
									bodyStyle={{ display: 'flex' }}
									actions={[
										<Tooltip title='Редактировать' key='edit'>
											<Button
												onClick={() => history.push(`/article/${article._id}/edit`)}
												type='primary'
												style={{ width: '95%' }}
												ghost
												block
												icon={<EditFilled />}
											/>
										</Tooltip>,
										<Tooltip title='Просмотр статьи на основном сайте' key='edit'>
											<Button
												onClick={() => openArticle(article._id, article.humanUrl)}
												style={{ width: '95%' }}
												block
												icon={<EyeFilled />}
											/>
										</Tooltip>,
										<Tooltip title='Удалить' key='delete'>
											<Button
												onClick={() => confirmDelete(article._id, article.title)}
												style={{ width: '95%' }}
												block
												danger
												icon={<DeleteOutlined />}
											/>
										</Tooltip>,
									]}
								>
									<div className='articles__image'>
										<img src={article.imageUrl} alt={article.title} />
									</div>
									<div className='articles__description'>
										<ReactMarkdown>{article.description}</ReactMarkdown>
									</div>
								</Card>
							</Col>
						))}
					</Row>
				</div>
			</div>
		</MainLayout>
	);
};

export default observer(ArticleListPage);
