import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { PieChartOutlined, UserOutlined, AppstoreAddOutlined, EditOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import AppHeader from './AppHeader';
import Logo from '../Logo';
import Breadcrumbs from './Breadcrumbs';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

interface MainLayoutProps {
	pageName: string;
	layoutWhite?: boolean;
	layoutNoPadding?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({ pageName, layoutWhite = true, layoutNoPadding = false, children }) => {
	const [collapsed, setCollapsed] = useState(false);
	const handleOnCollapse = (payload: boolean) => {
		setCollapsed(payload);
	};

	const location = useLocation();
	const history = useHistory();
	const handleMenuClick = (e: any) => {
		const { key: link } = e;
		if (link && !link.includes('sub')) {
			history.push(link);
		}
	};

	let layoutStyles = 'layout';
	if (layoutWhite) {
		layoutStyles += ' layout--white';
	}
	if (layoutNoPadding) {
		layoutStyles += ' layout--no-padding';
	}

	return (
		<div>
			<Layout style={{ minHeight: '100vh' }}>
				<Sider collapsible collapsed={collapsed} onCollapse={handleOnCollapse}>
					<Logo dark collapsed={collapsed} />
					<Menu
						theme='dark'
						selectedKeys={[location.pathname]}
						defaultOpenKeys={['subProducts', 'subArticles']}
						mode='inline'
						onClick={handleMenuClick}
					>
						<Menu.Item key='/' icon={<PieChartOutlined />}>
							Статистика
						</Menu.Item>
						<Menu.Item key='/users' icon={<UserOutlined />}>
							Пользователи
						</Menu.Item>
						<SubMenu key='subProducts' icon={<AppstoreAddOutlined />} title='Товары'>
							<Menu.Item key='/products'>Список товаров</Menu.Item>
							<Menu.Item key='/product/add'>Добавить товар</Menu.Item>
							<Menu.Item key='/product/import'>Импорт товаров</Menu.Item>
							<Menu.Item key='/product/categories'>Категории товаров</Menu.Item>
							<Menu.Item key='/product/types'>Типы товаров</Menu.Item>
						</SubMenu>
						<SubMenu key='subArticles' icon={<EditOutlined />} title='Статьи'>
							<Menu.Item key='/articles'>Список статей</Menu.Item>
							<Menu.Item key='/article/add'>Добавить статью</Menu.Item>
						</SubMenu>
					</Menu>
				</Sider>
				<Layout className='site-layout'>
					<AppHeader pageName={pageName} />
					<Content style={{ margin: '0 16px' }}>
						<Breadcrumbs />
						<div className={layoutStyles}>{children}</div>
					</Content>
					<Footer style={{ textAlign: 'center' }}>ООО «ГринКЭП»</Footer>
				</Layout>
			</Layout>
		</div>
	);
};

export default observer(MainLayout);
