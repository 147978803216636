import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { IRegUser, IUser } from '../models/IUser';
import AuthService from '../services/AuthService';
import { AuthResponse } from '../models/response/AuthResponse';
import { API_URL } from '../http';
import UserService from '../services/UserService';

export default class UserStore {
	user = {} as IUser;

	users = [] as IUser[];

	isAuth = false;

	isLoading = false;

	error: string = '';

	userAddFormIsVisible = false;

	constructor() {
		makeAutoObservable(this);
	}

	setAuth(bool: boolean) {
		this.isAuth = bool;
	}

	setUser(user: IUser) {
		this.user = user;
	}

	setUsers(users: IUser[]) {
		this.users = users;
	}

	setLoading(payload: boolean) {
		this.isLoading = payload;
	}

	setError(payload: string) {
		this.error = payload;
	}

	setUserAddFormIsVisible(payload: boolean) {
		this.userAddFormIsVisible = payload;
	}

	async login(login: string, password: string) {
		this.setLoading(true);
		try {
			const response = await AuthService.login(login, password);
			localStorage.setItem('token', response.data.accessToken);
			this.setAuth(true);
			this.setUser(response.data.user);
		} catch (e) {
			console.log(e.response?.data?.message);
			this.setError(e.response?.data?.message || 'Ошибка запроса');
		} finally {
			this.setLoading(false);
		}
	}

	async registration(user: IRegUser) {
		this.setLoading(true);
		try {
			await AuthService.registration(user);
		} catch (e) {
			console.log(e.response?.data?.message);
			throw new Error(e.response?.data?.message);
		} finally {
			this.setLoading(false);
		}
	}

	async logout() {
		try {
			await AuthService.logout();
			localStorage.removeItem('token');
			this.setAuth(false);
			this.setUser({} as IUser);
		} catch (e) {
			console.log(e.response?.data?.message);
		}
	}

	async checkAuth() {
		this.setLoading(true);
		try {
			const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, { withCredentials: true });
			console.log(response);
			localStorage.setItem('token', response.data.accessToken);
			this.setAuth(true);
			this.setUser(response.data.user);
		} catch (e) {
			console.log(e.response?.data?.message);
		} finally {
			this.setLoading(false);
		}
	}

	async getUsers() {
		this.setLoading(true);
		try {
			const response = await UserService.fetchUsers();
			this.setUsers(response.data);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async deleteUser(id: string) {
		this.setLoading(true);
		try {
			await UserService.deleteUser(id);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}
}
