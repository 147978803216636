import React from 'react';
import { Alert, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import MainLayout from './app/MainLayout';

const NotFound = () => {
	const history = useHistory();
	return (
		<MainLayout pageName='Страница не найдена'>
			<Alert
				type='error'
				message='404'
				description='Страница не найдена'
				showIcon
				action={
					<Button danger icon={<LeftOutlined />} onClick={() => history.push('/')}>
						Вернуться на главную
					</Button>
				}
			/>
		</MainLayout>
	);
};

export default NotFound;
