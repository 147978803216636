import React, { useContext } from 'react';
import { Pie } from '@ant-design/charts';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';

const StatsOfProducts = () => {
	const { productStore } = useContext(Context);

	const data = [
		{
			type: 'Серия 3.901-13 РУЧНЫЕ',
			value:
				productStore.products.filter(
					item => item.category?.description === 'serial' && item.type?.description === 'manual'
				).length || 0,
		},
		{
			type: 'Серия 3.901-13 ЭЛЕКТРОПРИВОД',
			value:
				productStore.products.filter(
					item => item.category?.description === 'serial' && item.type?.description === 'electric'
				).length || 0,
		},
		{
			type: 'Колонки из чугуна РУЧНЫЕ',
			value:
				productStore.products.filter(
					item => item.category?.description === 'patent' && item.type?.description === 'manual'
				).length || 0,
		},
		{
			type: 'Колонки из чугуна ЭЛЕКТРОПРИВОД',
			value:
				productStore.products.filter(
					item => item.category?.description === 'patent' && item.type?.description === 'electric'
				).length || 0,
		},
	];
	const config = {
		appendPadding: 10,
		data,
		angleField: 'value',
		colorField: 'type',
		radius: 1,
		innerRadius: 0.6,
		label: {
			type: 'inner',
			offset: '-50%',
			content: '{value}',
			style: {
				textAlign: 'center',
				fontSize: 14,
			},
		},
		statistic: {
			title: false,
			content: {
				style: {
					whiteSpace: 'pre-wrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					fontSize: 20,
				},
				content: `Всего\n${productStore.products.length}`,
			},
		},
		interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
	};
	// @ts-ignore
	return <Pie {...config} />;
};

export default observer(StatsOfProducts);
