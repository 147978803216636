import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import UserStore from './store/userStore';
import ProductStore from './store/productStore';
import ArticleStore from './store/articleStore';

interface RootState {
	userStore: UserStore;
	productStore: ProductStore;
	articleStore: ArticleStore;
}

const userStore = new UserStore();
const productStore = new ProductStore();
const articleStore = new ArticleStore();

// eslint-disable-next-line import/prefer-default-export
export const Context = createContext<RootState>({
	userStore,
	productStore,
	articleStore,
});

ReactDOM.render(
	<Context.Provider value={{ userStore, productStore, articleStore }}>
		<App />
	</Context.Provider>,
	document.getElementById('root')
);
