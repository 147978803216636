import { AxiosResponse } from 'axios';
import $api from '../http';
import { AuthResponse } from '../models/response/AuthResponse';
import { IRegUser } from '../models/IUser';

export default class AuthService {
	static async login(login: string, password: string): Promise<AxiosResponse<AuthResponse>> {
		return $api.post<AuthResponse>('/login', { login, password });
	}

	static async registration(user: IRegUser): Promise<AxiosResponse<AuthResponse>> {
		return $api.post<AuthResponse>('/registration', { ...user });
	}

	static async logout(): Promise<void> {
		return $api.post('/logout');
	}
}
