import { AxiosResponse } from 'axios';
import $api from '../http';
import { IArticle, IArticleDto } from '../models/IArticle';

export default class ArticleService {
	static fetchArticles(): Promise<AxiosResponse<IArticle[]>> {
		return $api.get<IArticle[]>(`/articles`);
	}

	static fetchArticleById(id: string): Promise<AxiosResponse<IArticle>> {
		return $api.get<IArticle>(`/articles/${id}`);
	}

	static addArticle(article: IArticleDto): Promise<AxiosResponse<IArticle>> {
		return $api.post(`/articles`, article);
	}

	static updateArticle(article: IArticle): Promise<AxiosResponse<IArticle>> {
		return $api.patch<IArticle>(`/articles/${article._id}`, article);
	}

	static deleteArticle(id: string): Promise<AxiosResponse<IArticle>> {
		return $api.delete<IArticle>(`/articles/${id}`);
	}
}
