import { AxiosResponse } from 'axios';
import $api from '../http';
import { IProduct, IProductDto } from '../models/IProduct';
import { IProductCategory, IProductCategoryDto } from '../models/IProductCategory';
import IProductType, { IProductTypeDto } from '../models/IProductType';

export default class ProductService {
	static fetchProducts(): Promise<AxiosResponse<IProduct[]>> {
		return $api.get<IProduct[]>('/products?all=true');
	}

	static fetchProductById(id: string): Promise<AxiosResponse<IProduct>> {
		return $api.get<IProduct>(`/products/${id}`);
	}

	static addProduct(product: IProductDto): Promise<AxiosResponse<IProduct>> {
		return $api.post(`/products`, product);
	}

	static updateProduct(product: IProduct): Promise<AxiosResponse<IProduct>> {
		return $api.patch(`/products/${product._id}`, product);
	}

	static deleteProduct(id: string): Promise<AxiosResponse<IProduct>> {
		return $api.delete(`/products/${id}`);
	}

	static fetchCategories(): Promise<AxiosResponse<IProductCategory[]>> {
		return $api.get<IProductCategory[]>(`/product/categories`);
	}

	static fetchProductCategoryById(id: string): Promise<AxiosResponse<IProductCategory>> {
		return $api.get<IProductCategory>(`/product/categories/${id}`);
	}

	static addCategory(category: IProductCategoryDto): Promise<AxiosResponse<IProductCategory>> {
		return $api.post(`/product/categories`, category);
	}

	static updateCategory(category: IProductCategory): Promise<AxiosResponse<IProductCategory>> {
		return $api.patch(`/product/categories/${category._id}`, category);
	}

	static deleteCategory(id: string): Promise<AxiosResponse<IProductCategory>> {
		return $api.delete(`/product/categories/${id}`);
	}

	static fetchTypes(): Promise<AxiosResponse<IProductType[]>> {
		return $api.get<IProductType[]>(`/product/types`);
	}

	static fetchProductTypeById(id: string): Promise<AxiosResponse<IProductType>> {
		return $api.get<IProductType>(`/product/types/${id}`);
	}

	static addProductType(type: IProductTypeDto): Promise<AxiosResponse<IProductType>> {
		return $api.post(`/product/types`, type);
	}

	static updateProductType(type: IProductType): Promise<AxiosResponse<IProductType>> {
		return $api.patch(`/product/types/${type._id}`, type);
	}

	static deleteProductType(id: string): Promise<AxiosResponse<IProductType>> {
		return $api.delete(`/product/types/${id}`);
	}
}
