import React, { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Avatar, Button, Modal, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';
import { Context } from '../../index';
import { IUser } from '../../models/IUser';
import './user.scss';
import UserAddForm from './UserAddForm';

const UserListPage: FC<IPage> = ({ name }) => {
	const { userStore } = useContext(Context);
	useEffect(() => {
		if (!userStore.users.length) {
			userStore.getUsers();
		}
	}, []);

	const columns: ColumnsType = [
		{
			title: '',
			align: 'center',
			width: 60,
			render: () => <Avatar src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png' />,
		},
		{
			title: 'Логин',
			dataIndex: 'login',
			key: 'login',
		},
		{
			title: 'email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Действия',
			align: 'center',
			render: (text: any, record: any) => (
				<Space size='middle'>
					<Tooltip title='Удалить'>
						<Button
							onClick={() => confirmDelete(record)}
							type='primary'
							shape='circle'
							danger
							icon={<DeleteOutlined />}
						/>
					</Tooltip>
				</Space>
			),
		},
	];

	function confirmDelete(user: IUser) {
		if (!user) return;
		Modal.confirm({
			title: 'Подвердить удаление',
			icon: <ExclamationCircleOutlined />,
			content: `Вы действительно хотите удалить «${user.login}» ?`,
			okText: 'Подтверждаю',
			cancelText: 'Отмена',
			onOk: () => deleteUser(user.id),
		});
	}

	const deleteUser = async (userId: string) => {
		if (userId) {
			await userStore.deleteUser(userId);
			await userStore.getUsers();
		}
	};

	const handleCancel = () => {
		userStore.setUserAddFormIsVisible(false);
	};

	return (
		<MainLayout pageName={name}>
			<div className='user'>
				<div className='user__actions'>
					<Button type='primary' icon={<UserAddOutlined />} onClick={() => userStore.setUserAddFormIsVisible(true)}>
						Добавить пользователя
					</Button>
				</div>
				<Table dataSource={userStore.users} columns={columns} rowKey='id' />
				<Modal
					visible={userStore.userAddFormIsVisible}
					title='Регистрация нового пользователя'
					onCancel={handleCancel}
					footer={[]}
				>
					<UserAddForm />
				</Modal>
			</div>
		</MainLayout>
	);
};

export default observer(UserListPage);
