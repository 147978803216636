import React, { FC, useContext, useEffect, useState } from 'react';
import RichMarkdownEditor from 'rich-markdown-editor';
import { Alert, Button, Card, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';
import './article-add.scss';
import { IArticleDto } from '../../models/IArticle';
import { Context } from '../../index';
import { transitCyrToLat } from '../../helpers/cyrToLatTranslator';
import { checkValid, handleUploadImage } from '../../helpers/articleHelpers';

const ArticleAddPage: FC<IPage> = ({ name }) => {
	const { articleStore } = useContext(Context);
	const history = useHistory();
	const [title, setTitle] = useState<string>('');
	const [humanUrl, setHumanUrl] = useState<string>('');
	const [imageUrl, setImageUrl] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [content, setContent] = useState<string>('');
	const [isValid, setIsValid] = useState<boolean>(false);
	const [imageUploadedList, setImageUploadedList] = useState<any[]>([]);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const validationResult = checkValid(title, description, content, imageUrl, humanUrl, imageUploadedList);
		setIsValid(validationResult);
	}, [title, imageUrl, description, content, imageUploadedList, humanUrl]);

	useEffect(() => {
		setHumanUrl(transitCyrToLat(title));
	}, [title]);

	const handleAddArticle = async () => {
		const article: IArticleDto = {
			title,
			description,
			content,
			imageUrl,
			humanUrl,
		};
		const resp = await articleStore.addArticle(article);
		if (resp && resp.status === 200) {
			history.push('/articles');
		} else {
			setError(resp?.statusText || 'Ошибка запрсоа');
		}
	};

	const onArticleImageUpload = (uploadData: any) => {
		setImageUploadedList(uploadData.fileList);
		if (uploadData.file.status === 'done') {
			const response = JSON.parse(uploadData.file.xhr.response);
			if (response && response.url) {
				setImageUrl(response.url);
			}
		}
	};

	return (
		<MainLayout pageName={name}>
			{error && (
				<Alert
					message='Ошибка сервера'
					type='error'
					showIcon
					description={error}
					action={
						<Button size='small' danger onClick={() => history.push('/articles')}>
							Вернуться на страницу статей
						</Button>
					}
				/>
			)}
			{!error && (
				<div className='article'>
					<div className='article__input'>
						<Input
							addonBefore='Заголовок'
							placeholder='Заголовок статьи'
							value={title}
							onChange={e => setTitle(e.target.value)}
						/>
					</div>
					<div className='article__input'>
						<Input
							addonBefore='ЧПУ'
							placeholder='Адрес статьи для поисковиков'
							value={humanUrl}
							onChange={e => setHumanUrl(e.target.value)}
						/>
					</div>
					<Card title='Описание статьи' className='card' bodyStyle={{ minHeight: 100 }}>
						<RichMarkdownEditor
							onChange={value => setDescription(value)}
							uploadImage={async file => handleUploadImage(file)}
						/>
					</Card>
					<Card title='Текст статьи' className='card' bodyStyle={{ minHeight: 200 }}>
						<RichMarkdownEditor
							onChange={value => setContent(value)}
							uploadImage={async file => handleUploadImage(file)}
						/>
					</Card>
					<Upload
						data={{ upload_preset: 'kolonki' }}
						action='https://api.cloudinary.com/v1_1/dhjjnsrlo/image/upload'
						listType='picture'
						onChange={onArticleImageUpload}
						maxCount={1}
					>
						<Button className='article__action' icon={<UploadOutlined />}>
							Загрузить изображение для статьи (max: 1)
						</Button>
					</Upload>
					<div className='article__action article__action--center'>
						<Button disabled={!isValid} onClick={handleAddArticle} type='primary'>
							Добавить статью
						</Button>
					</div>
				</div>
			)}
		</MainLayout>
	);
};

export default ArticleAddPage;
