import React, { FC, useEffect } from 'react';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';

const ProductImportPage: FC<IPage> = ({ name }) => {
	useEffect(() => {
		console.log('Loading page', name);
	}, [name]);
	return (
		<MainLayout pageName={name}>
			<h2>
				Функциональность будет доработана по надобности. Сейчас все товары быстро подгружаются из дампа БД при
				развертывании приложения в докере
			</h2>
		</MainLayout>
	);
};

export default ProductImportPage;
