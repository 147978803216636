import { makeAutoObservable } from 'mobx';
import { IArticle, IArticleDto } from '../models/IArticle';
import ArticleService from '../services/ArticleService';

export default class ArticleStore {
	articles = [] as IArticle[];

	article = {} as IArticle;

	isLoading = false;

	constructor() {
		makeAutoObservable(this);
	}

	setArticles(payload: IArticle[]) {
		this.articles = payload;
	}

	setArticle(payload: IArticle) {
		this.article = payload;
	}

	setLoading(payload: boolean) {
		this.isLoading = payload;
	}

	async getArticles() {
		this.setLoading(true);
		try {
			const response = await ArticleService.fetchArticles();
			this.setArticles(response.data);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async getArticleById(id: string) {
		this.setLoading(true);
		try {
			const response = await ArticleService.fetchArticleById(id);
			this.setArticle(response.data);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async addArticle(article: IArticleDto) {
		this.setLoading(true);
		try {
			const response = await ArticleService.addArticle(article);
			return response;
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async updateArticle(article: IArticle) {
		this.setLoading(true);
		try {
			const response = await ArticleService.updateArticle(article);
			return response;
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async deleteArticle(id: string) {
		this.setLoading(true);
		try {
			const response = await ArticleService.deleteArticle(id);
			await this.getArticles();
			return response;
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}
}
