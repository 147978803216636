import { makeAutoObservable } from 'mobx';
import ProductService from '../services/ProductService';
import { IProduct, IProductDto } from '../models/IProduct';
import { IProductCategory, IProductCategoryDto } from '../models/IProductCategory';
import IProductType, { IProductTypeDto } from '../models/IProductType';

export default class ProductStore {
	products = [] as IProduct[];

	product = {} as IProduct;

	categories = [] as IProductCategory[];

	category = {} as IProductCategory;

	types = [] as IProductType[];

	type = {} as IProductType;

	isLoading = false;

	constructor() {
		makeAutoObservable(this);
	}

	setProducts(payload: IProduct[]) {
		this.products = payload;
	}

	setProduct(payload: IProduct) {
		this.product = payload;
	}

	setLoading(payload: boolean) {
		this.isLoading = payload;
	}

	setCategories(payload: IProductCategory[]) {
		this.categories = payload;
	}

	setCategory(payload: IProductCategory) {
		this.category = payload;
	}

	setTypes(payload: IProductType[]) {
		this.types = payload;
	}

	setType(payload: IProductType) {
		this.type = payload;
	}

	async getProducts() {
		this.setLoading(true);
		try {
			const response = await ProductService.fetchProducts();
			this.setProducts(response.data);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async getProductById(id: string) {
		this.setLoading(true);
		try {
			const response = await ProductService.fetchProductById(id);
			console.log('try get product by id', response.data);
			this.setProduct(response.data);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async addProduct(product: IProductDto) {
		try {
			const response = await ProductService.addProduct(product);
			return response;
		} catch (e) {
			console.log(e);
		}
	}

	async updateProduct(product: IProduct) {
		try {
			const response = await ProductService.updateProduct(product);
			return response;
		} catch (e) {
			console.log(e);
		}
	}

	async deleteProduct(id: string) {
		this.setLoading(true);
		try {
			const response = await ProductService.deleteProduct(id);
			await this.getProducts();
			return response;
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async getProductCategories() {
		try {
			const response = await ProductService.fetchCategories();
			this.setCategories(response.data);
		} catch (e) {
			console.log(e);
		}
	}

	async getProductCategoryById(id: string) {
		this.setLoading(true);
		try {
			const response = await ProductService.fetchProductCategoryById(id);
			this.setCategory(response.data);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async addProductCategory(category: IProductCategoryDto) {
		try {
			const response = await ProductService.addCategory(category);
			return response;
		} catch (e) {
			console.log(e);
		}
	}

	async updateProductCategory(category: IProductCategory) {
		this.setLoading(true);
		try {
			const response = await ProductService.updateCategory(category);
			return response;
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async deleteCategory(id: string) {
		this.setLoading(true);
		try {
			const response = await ProductService.deleteCategory(id);
			await this.getProductCategories();
			return response;
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async getProductTypes() {
		try {
			const response = await ProductService.fetchTypes();
			this.setTypes(response.data);
		} catch (e) {
			console.log(e);
		}
	}

	async getProductTypeById(id: string) {
		this.setLoading(true);
		try {
			const response = await ProductService.fetchProductTypeById(id);
			this.setType(response.data);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async addProductType(type: IProductTypeDto) {
		try {
			const response = await ProductService.addProductType(type);
			return response;
		} catch (e) {
			console.log(e);
		}
	}

	async updateProductType(type: IProductType) {
		this.setLoading(true);
		try {
			const response = await ProductService.updateProductType(type);
			return response;
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}

	async deleteProductType(id: string) {
		this.setLoading(true);
		try {
			const response = await ProductService.deleteProductType(id);
			await this.getProductTypes();
			return response;
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	}
}
