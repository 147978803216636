import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

interface LogoProps {
	dark?: boolean;
	collapsed?: boolean;
}

const Logo: FC<LogoProps> = ({ dark = false, collapsed = false }) => {
	const history = useHistory();
	let classes: string = 'logo';

	if (dark) {
		classes += ' logo--dark';
	}

	if (collapsed) {
		classes += ' logo--collapsed';
	}

	const handleClick = () => {
		if (history) {
			history.push('/');
		}
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div className={classes} onClick={handleClick}>
			<img className='logo__image' src='/logo.png' alt='ООО «ГринКЭП»' />
			<span>ООО «ГринКЭП»</span>
		</div>
	);
};

export default Logo;
