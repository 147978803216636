import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';
import { Context } from '../../index';
import IProductType from '../../models/IProductType';

const ProductTypeList: FC<IPage> = ({ name: pageName }) => {
	const { productStore } = useContext(Context);
	const history = useHistory();
	useEffect(() => {
		productStore.getProductTypes();
	}, []);

	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			width: 500,
		},
		{
			title: 'Категория',
			dataIndex: 'category',
			width: 500,
			render: (text: any, record: IProductType) => <span>{record.productCategory.name}</span>,
		},
		{
			title: 'Действия',
			render: (text: any, record: IProductType) => (
				<Space size='middle'>
					<Tooltip title='Редактировать'>
						<Button
							onClick={() => history.push(`/product/types/${record._id}/edit`)}
							type='primary'
							shape='circle'
							icon={<EditOutlined />}
						/>
					</Tooltip>
					<Tooltip title='Удалить'>
						<Button
							onClick={() => productStore.deleteProductType(record._id)}
							type='primary'
							shape='circle'
							danger
							icon={<DeleteOutlined />}
						/>
					</Tooltip>
				</Space>
			),
		},
	];

	return (
		<MainLayout pageName={pageName}>
			<div className='product__action'>
				<Button onClick={() => history.push('/product/types/add')} size='small' type='primary' icon={<PlusOutlined />}>
					Добавить
				</Button>
			</div>
			<Table columns={columns} dataSource={productStore.types} rowKey='_id' />
		</MainLayout>
	);
};

export default observer(ProductTypeList);
