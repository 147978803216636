import React, { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';
import { Context } from '../../index';
import ProductList from './ProductList';
import './product-list.scss';

const ProductListPage: FC<IPage> = ({ name }) => {
	const { productStore } = useContext(Context);

	useEffect(() => {
		productStore.getProducts();
	}, []);
	return (
		<MainLayout pageName={name}>
			<ProductList products={productStore.products} />
		</MainLayout>
	);
};

export default observer(ProductListPage);
