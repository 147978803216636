import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import { Context } from './index';
import AuthPage from './features/auth/AuthPage';
import './styles/style.scss';
import routes from './router/routes';
import NotFound from './components/NotFound';

function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<Alert type='error' message={error.message}>
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
			<button type='button' onClick={resetErrorBoundary}>
				Try again
			</button>
		</Alert>
	);
}

const App: React.FunctionComponent = () => {
	const { userStore } = useContext(Context);

	if (!userStore.isAuth) {
		return (
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<AuthPage name='Вход в панель управления' />
			</ErrorBoundary>
		);
	}

	return (
		<div>
			<BrowserRouter>
				<Switch>
					{routes.map(route => (
						<Route
							key={route.path}
							path={route.path}
							exact={route.exact}
							render={(props: RouteComponentProps<any>) => (
								<ErrorBoundary FallbackComponent={ErrorFallback}>
									<route.component name={route.name} {...props} {...route.props} />
								</ErrorBoundary>
							)}
						/>
					))}
					<Route path='/product' exact>
						<Redirect to='/products' />
					</Route>
					<Route path='/article' exact>
						<Redirect to='/articles' />
					</Route>
					<Route component={NotFound} />
				</Switch>
			</BrowserRouter>
		</div>
	);
};

export default observer(App);
