import axios from 'axios';
import { IFileList } from '../models/IArticle';

export const checkValid = (
	title: string,
	description: string,
	content: string,
	imageUrl: string,
	humanUrl: string,
	imageUploadedList: IFileList[]
): boolean => {
	if (description.trim() === '\\' || content.trim() === '\\') {
		return false;
	}
	return !!(title && description && content && imageUrl && humanUrl && imageUploadedList.length > 0);
};

export const handleUploadImage = async (file: any) => {
	const url = 'https://api.cloudinary.com/v1_1/dhjjnsrlo/image/upload';
	const formData = new FormData();
	formData.append('file', file);
	formData.append('upload_preset', 'kolonki');
	const response = await axios.post(url, formData);
	return response.data.url;
};
