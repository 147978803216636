import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import { Context } from '../../index';
import { IUser } from '../../models/IUser';
import UserService from '../../services/UserService';
import LoginForm from './LoginForm';
import IPage from '../../models/IPage';
import './auth.scss';
import Logo from '../../components/Logo';

const AuthPage: FC<IPage> = (): ReactElement => {
	const { userStore: store } = useContext(Context);
	const [users, setUsers] = useState<IUser[]>([]);

	useEffect(() => {
		if (localStorage.getItem('token')) {
			store.checkAuth();
		}
	}, []);

	async function getUsers() {
		try {
			const response = await UserService.fetchUsers();
			setUsers(response.data);
		} catch (e) {
			console.log(e);
		}
	}

	if (store.isLoading) {
		return (
			<div className='loading'>
				<Spin size='large' />
			</div>
		);
	}

	if (!store.isAuth && !store.isLoading) {
		return (
			<div className='auth'>
				<div className='auth__container'>
					<Logo />
					<Card title='Вход только для администраторов'>
						<LoginForm />
					</Card>
				</div>
			</div>
		);
	}

	if (store.isAuth) {
		return <Redirect push to='/' />;
	}

	return (
		<div className=''>
			<h1>{store.isAuth ? `Пользователь авторизован ${store.user.email}` : 'АВТОРИЗУЙТЕСЬ'}</h1>
			<h1>{store.user.isActivated ? 'Аккаунт активирован' : 'Не активирован'}</h1>
			<button type='button' onClick={() => store.logout()}>
				Выйти
			</button>
			<div>
				<button type='button' onClick={getUsers}>
					Получить пользователей
				</button>
			</div>
			{users.map(user => (
				<div key={user.email}>{user.email}</div>
			))}
		</div>
	);
};

export default observer(AuthPage);
