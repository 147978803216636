import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import { IRegUser } from '../../models/IUser';

interface IFormData {
	login: string;
	email: string;
	password: string;
	confirmPassword: string;
}

const UserAddForm = () => {
	const { userStore } = useContext(Context);
	const [form] = Form.useForm();
	const [error, setError] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (!userStore.userAddFormIsVisible) {
			form.resetFields();
			setError('');
		}
	}, [userStore.userAddFormIsVisible]);

	const onSubmit = async (data: IFormData) => {
		if (data.password !== data.confirmPassword) return;
		const newUser: IRegUser = data;
		setIsLoading(true);
		try {
			await userStore.registration(newUser);
			form.resetFields();
			await userStore.getUsers();
			userStore.setUserAddFormIsVisible(false);
		} catch (e) {
			console.log('error of login', e.message);
			setError(e.message || 'Ошибка запроса');
		} finally {
			setIsLoading(false);
		}
	};

	const onFinish = (values: any) => {
		onSubmit(values);
	};

	const onFormChange = () => {
		setError('');
	};

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 10 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 14 },
		},
	};

	const tailFormItemLayout = {
		wrapperCol: {
			xs: {
				span: 24,
				offset: 8,
			},
			sm: {
				span: 14,
				offset: 9,
			},
		},
	};

	return (
		<>
			{error !== '' && (
				<div className='user__alert'>
					<Alert message='Внимание' type='error' showIcon description={error} />
				</div>
			)}
			<Form
				{...formItemLayout}
				form={form}
				name='register'
				onFinish={onFinish}
				onChange={onFormChange}
				scrollToFirstError
			>
				<Form.Item
					name='login'
					label='Логин'
					tooltip='Уникальный логин на латинице и без пробелов'
					rules={[
						{
							required: true,
							message: 'Обязательно для заполнения',
						},
						() => ({
							validator(_, value) {
								if (/^[a-zA-Z0-9]+$/gm.test(value)) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('Допускаются только латинские цифры и буквы без проблеьных симоволов'));
							},
						}),
					]}
				>
					<Input disabled={isLoading} />
				</Form.Item>
				<Form.Item
					name='email'
					label='Email'
					rules={[
						{ required: true, message: 'Обязательно для заполнения' },
						{ type: 'email', message: 'Укажите валидный email' },
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name='password'
					label='Пароль'
					rules={[
						{
							required: true,
							message: 'Обязательно для заполнения',
						},
					]}
					hasFeedback
				>
					<Input.Password disabled={isLoading} />
				</Form.Item>
				<Form.Item
					name='confirmPassword'
					label='Повторите пароль'
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: true,
							message: 'Обязательно для заполнения',
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('Пароли не совпадают!'));
							},
						}),
					]}
				>
					<Input.Password disabled={isLoading} />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button
						disabled={error !== ''}
						loading={isLoading}
						type='primary'
						htmlType='submit'
						style={{ marginTop: 10 }}
					>
						Добавить пользователя
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default observer(UserAddForm);
