import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from 'antd';
import { Context } from '../../index';
import IProductType from '../../models/IProductType';

const StatsOfProductTypes = () => {
	const { productStore } = useContext(Context);

	const columns = [
		{
			title: 'Категория',
			dataIndex: 'category',
			width: 500,
			render: (text: any, record: IProductType) => <span>{record.productCategory.name}</span>,
		},
		{
			title: 'Тип товара',
			dataIndex: 'name',
			key: 'name',
			width: 500,
		},
	];

	return (
		<>
			<Table columns={columns} dataSource={productStore.types} rowKey='_id' />
		</>
	);
};

export default observer(StatsOfProductTypes);
