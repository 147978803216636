import { AxiosResponse } from 'axios';
import $api from '../http';
import { IUser } from '../models/IUser';

export default class UserService {
	static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
		return $api.get<IUser[]>('/users');
	}

	static deleteUser(id): Promise<AxiosResponse<void>> {
		return $api.delete(`/users/${id}`);
	}
}
