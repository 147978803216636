import React, { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';
import { Context } from '../../index';
import ArticleEdit from './ArticleEdit';

interface ParamTypes {
	id: string;
}

const ArticleEditPage: FC<IPage> = ({ name }) => {
	const { id } = useParams<ParamTypes>();
	const { articleStore } = useContext(Context);

	useEffect(() => {
		articleStore.getArticleById(id);
	}, []);

	if (articleStore.article?._id) {
		const { imageUrl } = articleStore.article;
		const fileList = [
			{
				uid: '-1',
				name: imageUrl.substring(imageUrl.lastIndexOf('/') + 1),
				status: 'done',
				url: imageUrl,
			},
		];
		return (
			<MainLayout pageName={name}>
				<ArticleEdit article={articleStore.article} fileList={fileList} />
			</MainLayout>
		);
	}

	return (
		<MainLayout pageName={name}>
			<span>loading...</span>
		</MainLayout>
	);
};

export default observer(ArticleEditPage);
