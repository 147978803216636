import IRoute from '../models/IRoute';
import StatisticPage from '../features/statistic/StatisticPage';
import AuthPage from '../features/auth/AuthPage';
import UserListPage from '../features/user/UserListPage';
import ProductListPage from '../features/productList/ProductListPage';
import ProductEditPage from '../features/productEdit/ProductEditPage';
import ProductAddPage from '../features/productAdd/ProductAddPage';
import ProductImportPage from '../features/productImport/ProductImportPage';
import ProductCategoryList from '../features/productCategory/ProductCategoryList';
import ProductTypeList from '../features/productType/ProductTypeList';
import ArticleListPage from '../features/articleList/ArticleListPage';
import ArticleAddPage from '../features/articleAdd/ArticleAddPage';
import ArticleEditPage from '../features/articleEdit/ArticleEditPage';
import ProductCategoryAdd from '../features/productCategory/ProductCategoryAdd';
import ProductCategoryEdit from '../features/productCategory/ProductCategoryEdit';
import ProductTypeAdd from '../features/productType/ProductTypeAdd';
import ProductTypeEdit from '../features/productType/ProductTypeEdit';

const routes: IRoute[] = [
	{
		path: '/',
		name: 'Главная страница',
		component: StatisticPage,
		exact: true,
	},
	{
		path: '/auth',
		name: 'Вход в панель администратора',
		component: AuthPage,
		exact: true,
	},
	{
		path: '/users',
		name: 'Список пользователей',
		component: UserListPage,
		exact: true,
	},
	{
		path: '/products',
		name: 'Товары',
		component: ProductListPage,
		exact: true,
	},
	{
		path: '/product/:id/edit',
		name: 'Редактирование товара',
		component: ProductEditPage,
		exact: true,
	},
	{
		path: '/product/add',
		name: 'Добавление товара',
		component: ProductAddPage,
		exact: true,
	},
	{
		path: '/product/import',
		name: 'Импорт товаров',
		component: ProductImportPage,
		exact: true,
	},
	{
		path: '/product/categories',
		name: 'Категории товаров',
		component: ProductCategoryList,
		exact: true,
	},
	{
		path: '/product/categories/add',
		name: 'Добавление категории товара',
		component: ProductCategoryAdd,
		exact: true,
	},
	{
		path: '/product/categories/:id/edit',
		name: 'Редактирвоание категории товара',
		component: ProductCategoryEdit,
		exact: true,
	},
	{
		path: '/product/types',
		name: 'Типы товаров',
		component: ProductTypeList,
		exact: true,
	},
	{
		path: '/product/types/add',
		name: 'Добавление типа товара',
		component: ProductTypeAdd,
		exact: true,
	},
	{
		path: '/product/types/:id/edit',
		name: 'Редактирвоание типа товара',
		component: ProductTypeEdit,
		exact: true,
	},
	{
		path: '/articles',
		name: 'Список статей',
		component: ArticleListPage,
		exact: true,
	},
	{
		path: '/article/add',
		name: 'Добавить статью',
		component: ArticleAddPage,
		exact: true,
	},
	{
		path: '/article/:id/edit',
		name: 'Редактировать статью',
		component: ArticleEditPage,
		exact: true,
	},
];

export default routes;
