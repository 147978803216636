import React, { FC, useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';
import { Context } from '../../index';
import IProductType from '../../models/IProductType';
import { IProductDto } from '../../models/IProduct';

const ProductAddPage: FC<IPage> = ({ name }) => {
	const { productStore } = useContext(Context);
	const history = useHistory();
	const [typeDisabled, setTypeDisabled] = useState<boolean>(true);
	const [productTypes, setProductTypes] = useState<IProductType[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [form] = Form.useForm();

	useEffect(() => {
		if (!productStore.categories.length) {
			productStore.getProductCategories();
		}
		if (!productStore.types.length) {
			productStore.getProductTypes();
		}
	}, []);

	const onFinish = async (productData: IProductDto) => {
		console.log('Success:', productData);
		const resp = await productStore.addProduct(productData);
		if (resp && resp.status === 200) {
			history.push('/products');
		} else {
			setError(resp?.data.toString() || 'Ошибка запрсоа');
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const onCategoryChange = (value: string) => {
		form.setFieldsValue({ productTypeId: '' });
		if (value) {
			const targetProductTypes = productStore.types.filter(item => item.productCategory._id === value);
			setProductTypes(targetProductTypes);
			setTypeDisabled(false);
		} else {
			setTypeDisabled(true);
		}
	};

	return (
		<MainLayout pageName={name}>
			{error && (
				<Alert
					message='Ошибка сервера'
					type='error'
					showIcon
					description={error}
					action={
						<Button size='small' danger onClick={() => history.push('/products')}>
							Вернуться назад
						</Button>
					}
				/>
			)}
			{!error && (
				<Form
					name='basic'
					layout='vertical'
					form={form}
					initialValues={{ remember: true }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Row gutter={[40, 0]} justify='start'>
						<Col span={12}>
							<Form.Item
								label='Название'
								name='name'
								rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label='Альтернативное название' name='displayName'>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label='Описание' name='description'>
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label='Стоимость (руб)' name='price'>
								<InputNumber
									formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
									parser={value => parseFloat(`${value}`.replace(/\$\s?|(\s*)/g, ''))}
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label='Артикул' name='code'>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label='Категория'
								name='productCategoryId'
								rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
							>
								<Select onChange={onCategoryChange} allowClear>
									{productStore.categories.map(category => (
										<Select.Option key={category._id} value={category._id}>
											{category.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label='Тип'
								name='productTypeId'
								rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
							>
								<Select disabled={typeDisabled}>
									{productTypes.map(productType => (
										<Select.Option key={productType._id} value={productType._id}>
											{productType.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label='Ссылка на изображение' name='imgUrl'>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item>
								<Button type='primary' htmlType='submit'>
									Добавить товар
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			)}
		</MainLayout>
	);
};

export default observer(ProductAddPage);
