import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';
import { Context } from '../../index';
import { IProductCategory, IProductCategoryDto } from '../../models/IProductCategory';

interface ParamTypes {
	id: string;
}

const ProductCategoryEdit: FC<IPage> = ({ name: pageName }) => {
	const { id } = useParams<ParamTypes>();
	const { productStore } = useContext(Context);
	const history = useHistory();
	const [error, setError] = useState<string | null>(null);
	const [form] = Form.useForm();

	useEffect(() => {
		productStore.getProductCategoryById(id);
	}, []);

	useEffect(() => {
		const { name, displayName, description } = productStore.category;
		form.setFieldsValue({
			name,
			displayName,
			description,
		});
	}, [productStore.category]);

	const onFinish = async (categoryData: IProductCategoryDto) => {
		const updatedCategory: IProductCategory = { ...categoryData, _id: productStore.category._id };
		const resp = await productStore.updateProductCategory(updatedCategory);
		if (resp && resp.status === 200) {
			history.push('/product/categories');
		} else {
			setError(resp?.statusText || 'Ошибка запрсоа');
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<MainLayout pageName={pageName}>
			<span>category: {productStore.category.name}</span>
			{error && (
				<Alert
					message='Ошибка сервера'
					type='error'
					showIcon
					description={error}
					action={
						<Button size='small' danger onClick={() => history.push('/product/categories')}>
							Вернуться на страницу категорий
						</Button>
					}
				/>
			)}
			{!error && (
				<Form
					form={form}
					name='editProductCategory'
					layout='vertical'
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Row gutter={[40, 0]} justify='start'>
						<Col span={12}>
							<Form.Item
								label='Название'
								name='name'
								rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label='Альтернативное название' name='displayName'>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label='Описание' name='description'>
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item>
								<Button type='primary' htmlType='submit'>
									Сохранить
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			)}
		</MainLayout>
	);
};

export default observer(ProductCategoryEdit);
