import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Card, Input, Upload } from 'antd';
import RichMarkdownEditor from 'rich-markdown-editor';
import { UploadOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { IArticle, IFileList } from '../../models/IArticle';
import { transitCyrToLat } from '../../helpers/cyrToLatTranslator';
import { Context } from '../../index';
import { checkValid, handleUploadImage } from '../../helpers/articleHelpers';

interface ArticleEditProps {
	article: IArticle;
	fileList: IFileList[];
}

const ArticleEdit: FC<ArticleEditProps> = ({ article, fileList }) => {
	const { articleStore } = useContext(Context);
	const history = useHistory();
	const [title, setTitle] = useState<string>('');
	const [humanUrl, setHumanUrl] = useState<string>('');
	const [imageUrl, setImageUrl] = useState<string>('');
	const [description, setDescription] = useState<string>(articleStore.article.description);
	const [content, setContent] = useState<string>('');
	const [isValid, setIsValid] = useState<boolean>(false);
	const [imageUploadedList, setImageUploadedList] = useState<any>([]);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const validationResult = checkValid(title, description, content, imageUrl, humanUrl, imageUploadedList);
		setIsValid(validationResult);
	}, [title, imageUrl, description, content, imageUploadedList, humanUrl]);

	useEffect(() => {
		setHumanUrl(transitCyrToLat(title));
	}, [title]);

	useEffect(() => {
		initEditValues();
	}, []);

	useEffect(
		() => () => {
			articleStore.setArticle({} as IArticle);
		},
		[]
	);

	function initEditValues() {
		setTitle(article.title);
		setDescription(article.description);
		setContent(article.content);
		setImageUrl(article.imageUrl);
		setHumanUrl(article.humanUrl);
		setImageUploadedList(fileList);
	}

	const handleEditArticle = async () => {
		const updatedArticle: IArticle = {
			...article,
			title,
			description,
			content,
			imageUrl,
			humanUrl,
		};
		const resp = await articleStore.updateArticle(updatedArticle);
		if (resp && resp.status === 200) {
			history.push('/articles');
		} else {
			setError(resp?.statusText || 'Ошибка запрсоа');
		}
	};

	const onArticleImageUpload = (uploadData: any) => {
		setImageUploadedList(uploadData.fileList);
		if (uploadData.file.status === 'done') {
			const response = JSON.parse(uploadData.file.xhr.response);
			if (response && response.url) {
				setImageUrl(response.url);
			}
		}
	};

	return (
		<>
			{error && (
				<Alert
					message='Ошибка сервера'
					type='error'
					showIcon
					description={error}
					action={
						<Button size='small' danger onClick={() => history.push('/articles')}>
							Вернуться на страницу статей
						</Button>
					}
				/>
			)}
			{!error && (
				<div className='article'>
					<div className='article__input'>
						<Input
							addonBefore='Заголовок'
							placeholder='Заголовок статьи'
							value={title}
							onChange={e => setTitle(e.target.value)}
						/>
					</div>
					<div className='article__input'>
						<Input
							addonBefore='ЧПУ'
							placeholder='Адрес статьи для поисковиков'
							value={humanUrl}
							onChange={e => setHumanUrl(e.target.value)}
						/>
					</div>
					<Card title='Описание статьи' className='card' bodyStyle={{ minHeight: 100 }}>
						<RichMarkdownEditor
							defaultValue={article.description}
							onChange={value => setDescription(value)}
							uploadImage={async file => handleUploadImage(file)}
						/>
					</Card>
					<Card title='Текст статьи' className='card' bodyStyle={{ minHeight: 200 }}>
						<RichMarkdownEditor
							defaultValue={article.content}
							onChange={value => setContent(value)}
							uploadImage={async file => handleUploadImage(file)}
						/>
					</Card>
					<Upload
						data={{ upload_preset: 'kolonki' }}
						action='https://api.cloudinary.com/v1_1/dhjjnsrlo/image/upload'
						listType='picture'
						fileList={imageUploadedList}
						onChange={onArticleImageUpload}
						maxCount={1}
					>
						<Button className='article__action' icon={<UploadOutlined />}>
							Загрузить изображение для статьи (max: 1)
						</Button>
					</Upload>
					<div className='article__action article__action--center'>
						<Button disabled={!isValid} onClick={handleEditArticle} type='primary'>
							Сохранить
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

export default observer(ArticleEdit);
