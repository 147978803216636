import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Form, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import IPage from '../../models/IPage';
import { Context } from '../../index';
import MainLayout from '../../components/app/MainLayout';
import { IProductTypeDto } from '../../models/IProductType';

const ProductTypeAdd: FC<IPage> = ({ name: pageName }) => {
	const { productStore } = useContext(Context);
	const history = useHistory();
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (!productStore.categories.length) {
			productStore.getProductCategories();
		}
	}, []);

	const onFinish = async (typeData: IProductTypeDto) => {
		console.log('add type', typeData);
		const resp = await productStore.addProductType(typeData);
		if (resp && resp.status === 200) {
			history.push('/product/types');
		} else {
			setError(resp?.statusText || 'Ошибка запрсоа');
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<MainLayout pageName={pageName}>
			{error && (
				<Alert
					message='Ошибка сервера'
					type='error'
					showIcon
					description={error}
					action={
						<Button size='small' danger onClick={() => history.push('/product/types')}>
							Вернуться назад
						</Button>
					}
				/>
			)}
			{!error && (
				<Form name='basic' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed}>
					<Row gutter={[40, 0]} justify='start'>
						<Col span={12}>
							<Form.Item
								label='Название'
								name='name'
								rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label='Альтернативное название' name='displayName'>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label='Категория'
								name='productCategoryId'
								rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
							>
								<Select>
									{productStore.categories.map(category => (
										<Select.Option key={category._id} value={category._id}>
											{category.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label='Описание' name='description'>
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item>
								<Button type='primary' htmlType='submit'>
									Добавить категорию
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			)}
		</MainLayout>
	);
};

export default observer(ProductTypeAdd);
