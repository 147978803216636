import React, { FC, useContext } from 'react';
import { Button, Layout } from 'antd';
import '../../styles/header.scss';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';

interface HeaderProps {
	pageName?: string;
}

const { Header } = Layout;

const AppHeader: FC<HeaderProps> = ({ pageName }) => {
	const { userStore } = useContext(Context);

	const onLogout = async () => {
		await userStore.logout();
	};

	return (
		<Header className='header'>
			<h1>{pageName}</h1>
			<div className='user'>
				<span className='user__name'>{userStore.user?.login || 'userLogin'}</span>
				<Button className='user__logout' type='link' size='small' onClick={onLogout}>
					Выход
				</Button>
			</div>
		</Header>
	);
};

export default observer(AppHeader);
