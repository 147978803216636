import React, { FC, useContext, useEffect } from 'react';
import { Button, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';
import { Context } from '../../index';
import { IProductCategory } from '../../models/IProductCategory';

const ProductCategoryList: FC<IPage> = ({ name }) => {
	const { productStore } = useContext(Context);
	const history = useHistory();
	useEffect(() => {
		productStore.getProductCategories();
	}, []);

	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			width: 500,
		},
		{
			title: 'Действия',
			render: (text: any, record: IProductCategory) => (
				<Space size='middle'>
					<Tooltip title='Редактировать'>
						<Button
							onClick={() => history.push(`/product/categories/${record._id}/edit`)}
							type='primary'
							shape='circle'
							icon={<EditOutlined />}
						/>
					</Tooltip>
					<Tooltip title='Удалить'>
						<Button
							onClick={() => productStore.deleteCategory(record._id)}
							type='primary'
							shape='circle'
							danger
							icon={<DeleteOutlined />}
						/>
					</Tooltip>
				</Space>
			),
		},
	];

	return (
		<MainLayout pageName={name}>
			<div className='product__action'>
				<Button
					onClick={() => history.push('/product/categories/add')}
					size='small'
					type='primary'
					icon={<PlusOutlined />}
				>
					Добавить
				</Button>
			</div>
			<Table columns={columns} dataSource={productStore.categories} rowKey='_id' />
		</MainLayout>
	);
};

export default observer(ProductCategoryList);
