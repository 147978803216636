import React, { FC, useContext, useEffect } from 'react';
import { Button, Card, Col, Row, Statistic } from 'antd';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import IPage from '../../models/IPage';
import MainLayout from '../../components/app/MainLayout';
import StatsOfProducts from './StatsOfProducts';
import StatsOfProductTypes from './StatsOfProductTypes';
import { Context } from '../../index';

const StatisticPage: FC<IPage> = ({ name }) => {
	const { articleStore, userStore, productStore } = useContext(Context);
	const history = useHistory();

	useEffect(() => {
		articleStore.getArticles();
		userStore.getUsers();
		productStore.getProducts();
		productStore.getProductTypes();
	}, []);

	return (
		<MainLayout pageName={name} layoutWhite={false} layoutNoPadding>
			<div className='statistic'>
				<Row gutter={[20, 20]}>
					<Col span={24}>
						<Card
							bordered
							title='Товары'
							extra={
								<Button type='link' onClick={() => history.push('/products')}>
									Перейти
								</Button>
							}
						>
							<StatsOfProducts />
						</Card>
					</Col>
					<Col span={24}>
						<Card
							bordered
							title='Категории и типы товаров'
							actions={[
								<Button type='link' onClick={() => history.push('/product/categories')}>
									Категории товаров
								</Button>,
								<Button type='link' onClick={() => history.push('/product/types')}>
									Типы товаров
								</Button>,
							]}
						>
							<StatsOfProductTypes />
						</Card>
					</Col>
					<Col span={12}>
						<Card
							bordered
							title='Статьи'
							extra={
								<Button type='link' onClick={() => history.push('/articles')}>
									Перейти
								</Button>
							}
						>
							<Statistic title='Всего статей' value={articleStore.articles.length} />
						</Card>
					</Col>
					<Col span={12}>
						<Card
							bordered
							title='Пользователи'
							extra={
								<Button type='link' onClick={() => history.push('/users')}>
									Перейти
								</Button>
							}
						>
							<Statistic title='Всего пользователей' value={userStore.users.length} />
						</Card>
					</Col>
				</Row>
			</div>
		</MainLayout>
	);
};

export default observer(StatisticPage);
